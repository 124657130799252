.form-header {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.icon {
  width: 20px;
  height: 20px;
  color: var(--primary-color);
}

.title {
  margin-top: 14px;
}
