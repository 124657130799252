.label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 8px;

  .text {
    margin: 0 6px 0 0;
    padding: 2px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: var(--fourth-text-color);
  }

  & .disabled{
    color: var(--component-label-disabled-color);
  }

  & .negative{
    color:var(--color-negative);
  }

  .optional {
    padding: 2px 4px;
    background: var(--component-bg-tag-color);
    color: var(--component-label-disabled-color);
    border-radius: 4px;
    margin-left: 4px;
  }

  .required{
    padding: 2px 4px;
    background: var(--background-warning);
    color: var(--color-warning);
    border-radius: 4px;
    margin-left: 4px;
  }
  .required {
    padding: 2px 4px;
    background: var(--background-warning);
    color: var(--color-warning);
    border-radius: 4px;
  }
}
