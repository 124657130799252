.button {
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-align: center;
  width: 100%;
  height: 100%;
  min-width: max-content;
  box-shadow: 1px 2px 4px var(--button-shadow-color);

  .icon {
    align-items: center;
    display: flex;
  }

  &.primary {
    background-color: var(--background-primary);
    color: var(--text-color-primary);
    border: 1px solid var(--background-primary);

    &:hover {
      background-color: var(--background-primary-hover);
      transition: 0.4s;
      border: 1px solid var(--background-primary-hover);
    }

    &:active, &:focus {
      background-color: var(--background-primary-active);
      border: 1px solid var(--border-primary-active);
      outline: 1px solid var(--border-primary-active);
    }
    &:disabled{
      background-color: var(--background-primary);
      color: var(--text-color-primary);
      border: 1px solid var(--background-primary);
    }
  }

  &.secondary {
    background-color: var(--background-secondary);
    color: var(--text-color-secondary);
    border: 1px solid var(--border-secondary);

    &:hover {
      background-color: var(--background-secondary-hover);
      border: 1px solid var(--background-secondary-hover);
      transition: 0.4s;
    }

    &:active, &:focus {
      background: var(--background-secondary-active);
      border: 1px solid var(--border-secondary-active);
      outline: 1px solid var(--border-secondary-active);
    }
  }

  &.destructive {
    background-color: var(--background-destructive);
    color: var(--text-color-destructive);
    border: 1px solid var(--background-destructive);

    &:hover {
      background-color: var(--background-destructive-hover);
      border: 1px solid var(--background-destructive-hover);
      transition: 0.4s;
    }

    &:active, &:focus {
      background: var(--background-destructive-active);
      border: 1px solid var(--background-destructive-active);
      outline: 1px solid var(--background-destructive-active);
    }
  }

  &.negative {
    background-color: var(--background-negative);
    color: var(--text-color-negative);
    border: 1px solid var(--border-negative);

    &:hover {
      background-color: var(--background-negative-hover);
      border: 1px solid var(--background-negative-hover);
      transition: 0.4s;
    }

    &:active, &:focus {
      background: var(--background-negative-active);
      border: 1px solid var(--border-negative-active);
      outline: 1px solid var(--border-negative-active);
    }
  }

  &.upload {
    background-color: var(--light-grey-color);
    color: var(--fourth-text-color);
  }

  &.small {
    width: auto;
    padding: 8px 12px;

    .icon > span {
      font-size: 24px;
    }

    .text {
      margin: 0px 4px;
      font-family: 'Nunito', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &.large {
    font-size: 18px;
    line-height: 24px;
    min-height: 48px;
    height: 100%;
    max-width: 100%;
    padding: 12px 24px;

    .icon > span {
      font-size: 32px;
    }

    .text {
      margin: 0px 8px;
      font-family: 'Nunito', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
    }
  }

  &.iconOnly {
    padding: 6px;
  }
}
