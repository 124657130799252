.wrapper {
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  transform-style: preserve-3d;
  margin-bottom: 16px;
}

.fieldGrid {
  position: relative;
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;
  -ms-grid-columns: auto 1fr;
  grid-template-columns: auto 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;

}

.prependInput {
  display: flex;
  background-color: var(--component-border-color);
  padding: 0 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-content: center;
  -webkit-box-pack: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.container-input {
  display: flex;
  gap: 12px;
  align-items: center;
}

.textField {
  position: relative;
  width: 100%;
  padding: 8px 12px;
  font-size: 16px;
  line-height: 20px;
  vertical-align: middle;
  background-color: var(--component-bg-color);

  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 60px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border: 1px solid var(--sub-component-border-color);
  border-radius: 4px;
  color: var(--component-text-color);
  font-family: inherit;

  &:hover {
    border: 1px solid var(--sub-component-hover-border-color);
  }
  &:focus {
    outline: 2px solid var(--sub-component-focus-border-color);
  }

  &::placeholder {
    color: var(--third-text-color);
  }

  &.withIcon {
    padding-right: 40px;
  }
}

.textFieldSmall {
  height: 36px;
  width: 64px;
}

.textFieldLarge {
  height: 44px;
}

.icon {
  position: absolute;
  right: 10px;
  top: 13px;
  height: 24px;

  .externalLink {
    color: var(--color-main);
    opacity: 1;
  }

  &.clickable {
    pointer-events: auto;
    cursor: pointer;
  }
}

.negative{
  background: var(--background-negative);
  color: var(--color-negative);
  border-color: var(--sub-component-negative-border-color);

  &::placeholder {
    color: var(--color-negative);
  }
}

.characters{
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  margin-top: 4px;
  color: var(--third-text-color);
}
.charactersNegative{
  color: var(--color-negative);
}

.containerCharacters{
  display: flex;
  align-items: center;
  justify-content: space-between
}
