//remove it from here

.form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.pre-button-block {
  margin-top: 8px;
  margin-bottom: 14px;
  text-align: right;
}

.color-link {
  color: var(--primary-color);
  text-decoration: underline;
}

.pos-button-block {
  margin-top: 24px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}
