.container {
  padding: 8px 0;
  color: var(--component-label-color);
  display: flex;
  align-items: self-start;
}

.icon {
  min-width: 18px;
  line-height: 20px;
  margin-right: 6px;
}

.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  padding-top: 1px;
}

.neutral {
  color: var(--component-label-color);
}

.negative {
  color: var(--color-negative);
}

.positive {
  color: var(--color-positive);
}
