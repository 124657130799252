.footer {
  width: 100%;
  padding: 24px 0;
  border-top: 1px solid var(--light-grey-color);

  a {
    cursor: pointer;
    text-decoration: underline;
  }
  a:hover {
      color: var(--primary-color);
  }
}
